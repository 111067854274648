import React from 'react';

import Layout from 'components/layout';
import styled from 'styled-components/macro';
import SEO from 'components/SEO';
import { theme } from 'styled-tools';

const PageContent = styled.div`
  padding-top: 4rem;
  background-color: #000000;

  .error-text {
    color: #cc183c;
  }
`;

const MainContainer = styled.div`
  font-family: ${theme('fonts.title')};
  margin: 0 auto;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 60px 20px;
  justify-content: center;
  align-items: center;
`;

const ContentHeader = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
`;

const ContentParagraph = styled.div`
  margin-bottom: 15px;

  span {
    font-size: 18px;
    line-height: 28px;
  }

  a {
    color: #ffbc6c;
  }
`;

const HeaderText = styled.span`
  font-size: 34px;
`;

const ParagraphText = styled.span`
  font-size: 24px;
  line-height: 28px;

  a {
    color: #ffbc6c;
  }
`;

class PasswordResetPage extends React.PureComponent {
  render() {
    const href = typeof window !== `undefined` ? window.location.href : '';
    return (
      <Layout>
        <SEO title='Confirmation' />
        <PageContent>
          <MainContainer>
            <ContentHeader>
              <HeaderText>Email Confirmation</HeaderText>
            </ContentHeader>
            <ContentParagraph>
              <ParagraphText>
                Please open the <a href={href}>confirmation link</a> on your
                mobile device via the dough app to confirm your email change.
              </ParagraphText>
            </ContentParagraph>
          </MainContainer>
        </PageContent>
      </Layout>
    );
  }
}

export default PasswordResetPage;
